import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import './App.css';
import { AddClientCard } from "./components/cards/client/AddClientCard";
import { ErrorModal } from "./components/cards/ErrorModal";
import { ClientProvider } from "./contexts/ClientContext";
import { AppLayout } from "./main/AppLayout";
import { ModalLayout } from "./main/ModalLayout";
import { RequireAnonymous } from "./main/RequireAnonymous";
import { RequireAuth } from "./main/RequireAuth";
import { Login } from "./pages/anonymous/Login";
import { SingleClientView } from "./pages/app/client/SingleClientView";
import { Dashboard } from "./pages/app/Dashboard";
import { NotFound } from "./pages/NotFound";

function App() {
    const location = useLocation();
    const showError = location.state?.showError;

    return (
        <>
            <Routes>
                <Route path="/login" element={<RequireAnonymous><Login /></RequireAnonymous>} />
                <Route path="*" element={<RequireAuth><AppRoutes /></RequireAuth>} />
            </Routes>
            {
                showError && <ErrorModal error={showError} />
            }
        </>
    );
}


function AppRoutes(){
    const location = useLocation();
    const showModal = location.state?.showModal;
    
    return (
        <>
            {
                showModal && <ModalRoute routeLocation={showModal} />
            }
            <Routes>
                <Route path="/" element={<AppLayout />}>
                    <Route index element={<Dashboard />} />
                    <Route path="bucket">
                        <Route index element={<Dashboard />} />
                        <Route path=":bucketId" element={<ClientProvider />}>
                            <Route index element={<SingleClientView />} />
                        </Route>
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </>
    );
}

function ModalRoute({routeLocation}){
    return(
        <Routes location={routeLocation}>
            <Route path={"/"} element={<ModalLayout />}>
                <Route path={"bucket/add"} element={<AddClientCard />} />
            </Route>
        </Routes>
    );
}

export default App;
