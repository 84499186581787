import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./main/customCssClass.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        admin app now use the CMS extension as admin panel;
    </React.StrictMode>
);

/*
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <AuthProvider>
                <div className='app bg-light'>
                    <App />
                </div>
            </AuthProvider>
        </BrowserRouter>
  </React.StrictMode>
);

*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
